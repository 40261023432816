export function log (...args: any[]): void {
	if (
		useRuntimeConfig().public.ENVIRONMENT === "dev"
		&& args.length > 0
		&& args[0].trim() !== ""
	) {
		// eslint-disable-next-line no-console
		console.log(...args);
	}
}

export function compareValues (label: string, val1: any, val2: any): void {
	const isSame = val1 === val2;
	if (!isSame) {
		console.warn(`❌ ${label}`, val1, " |", val2);
	} else {
		// eslint-disable-next-line no-console
		console.log(`✅ ${label}`, val1, " |", val2);
	}
}

export function isOnDev (): boolean {
	return useRuntimeConfig().public.ENVIRONMENT === "dev";
}

export function onDev (callback: () => void): void {
	if (isOnDev()) {
		callback();
	}
}
