/**
 * @deprecated Use Sub instead
 */
export namespace PBEnums {
	/**
	 * @deprecated Use Sub.Type instead
	 */
	export enum SubscriptionType {
		ObjectStorage = "s3",
		Partner = "distributeur",
		Drive = "drive",
		DrivePro = "drive_pro"
	}

	export enum Roles {
		Owner = "owner",
		Admin = "admin",
		Invoice = "invoice",
		None = "none"
	}
}
