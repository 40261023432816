import type { RuntimeConfig } from "nuxt/schema";

import { StripeRaw } from "~/types/stripe/raw";
import { Sub } from "~/types/subscriptions";

import { SubscriptionValidator } from "~/classes/SubscriptionValidator";

/**
 * Configuration object for Subscription.
 */
export interface SubscriptionConfig {
	runtimeConfig: RuntimeConfig["public"];
	type: Sub.Type;
	status: Sub.Status;
	description: string;
	item: StripeRaw.Subscription;
	product: StripeRaw.Product;
	plan: StripeRaw.Plan;
}

/**
 * Abstract class representing a Subscription.
 */
export default abstract class Subscription {
	public id: string = "";

	/**
	 * Information of type of subscription.
	 */
	public type: Sub.Type;

	/**
	 * Information of the subscription status.
	 */
	public status: Sub.Status;

	/**
	 * Description of the subscription.
	 */
	public description: string = "";

	/**
	 * Information if is a trial subscription.
	 */
	public isTrial: boolean = false;

	/**
	 * Parent has paid for the subscription.
	 */
	public parentHasPaid: boolean = false;

	/**
	 * Date of the subscription start.
	 */
	public start: Date = new Date();

	/**
	 * Date of the subscription start cycle.
	 */
	public cycleStart: Date = new Date();

	/**
	 * Whether the subscription has been cancelled.
	 */
	public isCanceled: boolean = false;

	/**
	 * Represents the cancellation date (if applicable).
	 */
	public cancelAt: Date | null = null;

	/**
	 * Periodicity of the subscription.
	 */
	public periodicity: Sub.Periodicity = Sub.Periodicity.Monthly;

	/**
	 * Subscription item.
	 */
	public subscriptionItem: StripeRaw.Subscription;

	/**
	 * Product item.
	 */
	public productItem: StripeRaw.Product;

	/**
	 * Plan item.
	 */
	public planItem: StripeRaw.Plan;

	/**
	 * Runtime configuration object.
	 */
	protected runtimeConfig: RuntimeConfig["public"];

	/**
	 * Subscription constructor.
	 *
	 * @param config - The configuration object for the subscription.
	 * @throws {Error} If the subscription item is invalid.
	 * @throws {Error} If the product item is invalid.
	 * @throws {Error} If the plan item is invalid.
	 */
	protected constructor (config: SubscriptionConfig) {
		this.id = Math.random().toString(36).slice(2, 11);
		this.runtimeConfig = config.runtimeConfig;
		this.type = config.type;
		this.status = config.status ?? Sub.Status.None;
		this.isTrial = this.status === Sub.Status.Trialing;

		if (!SubscriptionValidator.isValidItemType(config.item, StripeRaw.ItemType.Subscription)) {
			throw new Error("Invalid subscription item");
		}
		this.subscriptionItem = config.item;

		if (!SubscriptionValidator.isValidItemType(config.product, StripeRaw.ItemType.Product)) {
			throw new Error("Invalid product item");
		}
		this.productItem = config.product;

		if (!SubscriptionValidator.isValidItemType(config.plan, StripeRaw.ItemType.Plan)) {
			throw new Error("Invalid plan item");
		}
		this.planItem = config.plan;

		log(`⚡️ Subscription created: ${this.type}`);

		this.defineStart();
		this.defineCycleStart();
		this.defineCancelAt();
		this.definePeriodicity();
		this.defineDescription();
		this.getTrialInformations();
	}

	/**
	 * Set the subscription status.
	 *
	 * @param status - The new status of the subscription.
	 * @throws {Error} If the status is not valid for the subscription.
	 */
	public setStatus (status: Sub.Status): void {
		if (!Object.values(Sub.Status).includes(status)) {
			throw new Error(`Status ${status} is not valid for subscription ${this.type}`);
		}

		this.status = status;
	}

	/**
	 * Gets the type of subscription.
	 *
	 * @returns The type of subscription.
	 */
	public getType (): Sub.Type {
		return this.type;
	}

	/**
	 * Gets the status of the subscription.
	 *
	 * @returns The status of the subscription.
	 */
	public getStatus (): Sub.Status {
		return this.status;
	}

	/**
	 * Get the creation date of the subscription.
	 *
	 * @param asTimestamp - Whether to return the date as a timestamp.
	 * @returns The creation date of the subscription.
	 */
	public getCreated (asTimestamp: boolean = false): Date | number {
		if (asTimestamp) {
			return this.subscriptionItem.created;
		}

		return new Date(this.subscriptionItem.created * 1000);
	}

	/**
	 * Retrieves trial information.
	 *
	 * @returns An object containing trial information or null if no trial is active.
	 */
	public getTrialInformations (): {
		isTrial: boolean;
		trialEnd: Date | null;
	} | null {
		return {
			isTrial: this.status === Sub.Status.Trialing,
			trialEnd: this.subscriptionItem.trial_end
				? new Date(this.subscriptionItem.trial_end * 1000)
				: null
		};
	}

	/**
	 * Retrieves the product name.
	 *
	 * @returns The product name.
	 */
	public getProductName (): string {
		return this.planItem.nickname ?? "";
	}

	/**
	 * Defines the start of the billing cycle based on the current period start of the subscription item.
	 */
	private defineStart (): void {
		if (this.subscriptionItem.start_date) {
			this.start = new Date(this.subscriptionItem.start_date * 1000);
		}
	}

	/**
	 * Defines the start of the billing cycle based on the current period start of the subscription item.
	 */
	private defineCycleStart (): void {
		if (this.subscriptionItem.current_period_start) {
			this.cycleStart = new Date(this.subscriptionItem.current_period_start * 1000);
		}
	}

	/**
	 * Defines the cancellation date based on the subscription item.
	 */
	private defineCancelAt (): void {
		if (this.subscriptionItem.cancel_at) {
			this.cancelAt = new Date(this.subscriptionItem.cancel_at * 1000);

			const now = new Date();
			if (this.cancelAt < now) {
				this.isCanceled = true;
			}
		}
	}

	/**
	 * Defines the periodicity of the subscription based on the plan item.
	 */
	private definePeriodicity (): void {
		if (this.planItem.interval === "year") {
			this.periodicity = Sub.Periodicity.Yearly;
		}
	}

	/**
	 * Defines the description of the subscription.
	 */
	private defineDescription (): void {
		this.description = this.productItem.description ?? this.planItem.nickname ?? "";
	}
}
