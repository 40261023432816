import type { StripeRaw } from "~/types/stripe/raw";
import type { Sub } from "~/types/subscriptions";

import { checkNoSubscription } from "~/composables/notifications";

export function waitForSubscription (subscriptionType: Sub.Type): Promise<void> {
	if (__subscription().getIsFetched) {
		void checkNoSubscription(true);
		return Promise.resolve();
	}

	if (__subscription().has(subscriptionType)) {
		void checkNoSubscription(true);
		return Promise.resolve();
	}

	return new Promise((resolve, reject) => {
		const timeout = setTimeout(() => {
			reject(new Error(`Subscription ${subscriptionType} timed out`));
			try {
				void checkNoSubscription(true);
			} catch (error) {
				console.error(`Error checking subscription: ${error}`);
			}
		}, 10000);

		const interval = setInterval(() => {
			if (__subscription().has(subscriptionType)) {
				clearTimeout(timeout);
				clearInterval(interval);
				resolve();
			}
		}, 100);
	});
}

export function getProductIDFromItem (item: StripeRaw.Subscription | undefined): string | null {
	if (!item) {
		return null;
	}

	const priceProduct = (item as any).price?.product as string;
	const planProduct = item.plan?.product as string;

	return (priceProduct || planProduct) ?? null;
}

export function getProductID (subscription: StripeRaw.Subscription): string | null {
	if ("items" in subscription) {
		const data = subscription.items?.data[0];
		return getProductIDFromItem(data);
	}

	return null;
}
