import type { AlertType } from "~/types/alert";
import type { StripeRaw } from "~/types/stripe/raw";
import { Sub } from "~/types/subscriptions";

/**
 * Classe de configuration pour les abonnements.
 */
export class SubscriptionConfig {
	/**
	 * Clés à vérifier dans les métadonnées de l'abonnement.
	 *
	 * @type {StripeRaw.MetadataKey[]}
	 */
	public static readonly keysToCheck: StripeRaw.MetadataKey[] = [ "abonnement", "abonnement_kc" ];

	/**
	 * Mapping des types d'abonnements possibles et leurs valeurs associées.
	 *
	 * @type {Record<Sub.Type, string[]>}
	 */
	public static readonly possiblesSubscriptionTypes: Record<Sub.Type, string[]> = {
		[Sub.Type.ObjectStorage]: [ "s3", "object-storage" ],
		[Sub.Type.Partner]: [ "partner", "distributeur" ],
		[Sub.Type.Drive]: [
			"drive",
			"solo",
			"perso",
			"equipe",
			"tribu",
			"expert"
		],
		[Sub.Type.DrivePro]: [ "pro", "drive-pro" ]
	};

	/**
	 * Priorité des statuts d'abonnement pour le traitement.
	 *
	 * @type {Sub.Status[]}
	 */
	public static readonly subscriptionsPriority: Sub.Status[] = [
		Sub.Status.Trialing,
		Sub.Status.Active,
		Sub.Status.PastDue,
		Sub.Status.Unpaid,
		Sub.Status.Incomplete,
		Sub.Status.IncompleteExpired
	];

	/**
	 * Statuts d'abonnement considérés comme valides.
	 *
	 * @type {Sub.Status[]}
	 */
	public static readonly subscriptionsConsideredAsValid: Sub.Status[] = [
		Sub.Status.Trialing,
		Sub.Status.Active,
		Sub.Status.PastDue
	];

	public static readonly statusLevels: Record<Sub.Status, AlertType | undefined> = {
		[Sub.Status.Trialing]: "info",
		[Sub.Status.Active]: "success",
		[Sub.Status.PastDue]: "warning",
		[Sub.Status.Unpaid]: "danger",
		[Sub.Status.Incomplete]: "danger",
		[Sub.Status.IncompleteExpired]: "danger",
		[Sub.Status.Canceled]: "danger",
		[Sub.Status.None]: undefined
	};

	/**
	 * Statuts d'abonnement considérés comme en lecture seule.
	 *
	 * @type {Sub.Status[]}
	 */
	public static readonly subscriptionsConsideredAsReadonly: Sub.Status[] = [ Sub.Status.Unpaid ];

	/**
	 * Represents the list of subscription that are allowed to be multiple.
	 *
	 * @type {Sub.Type[]}
	 */
	public static readonly subscriptionsAllowedToMultiple: Sub.Type[] = [ Sub.Type.DrivePro ];
}
