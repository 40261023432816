import { PBEnums } from "~/types/pb/enums";

const rolesScores: Record<PBEnums.Roles, number> = {
	[PBEnums.Roles.Owner]: 0,
	[PBEnums.Roles.Admin]: 1,
	[PBEnums.Roles.Invoice]: 2,
	[PBEnums.Roles.None]: 999
};

export function getRoleFromName (name: string): PBEnums.Roles {
	switch (name) {
	case "owner":
		return PBEnums.Roles.Owner;
	case "admin":
		return PBEnums.Roles.Admin;
	case "invoice":
		return PBEnums.Roles.Invoice;
	default:
		return PBEnums.Roles.None;
	}
}

export function getRoleScore (role: PBEnums.Roles): number {
	return rolesScores[role] ?? 999;
}
