import { ref, type ToRefs } from "vue";

import { __clients } from "~/stores/clients.store";

import { Sub } from "~/types/subscriptions";

import Subscription, { type SubscriptionConfig } from "~/classes/Subscription";

export interface ISubscriptionPartner {
	spaceAllowed: number;
	spaceAllowedMax: number;
	clientsAmount: number;
	route: string;
	isUnlimited: boolean;
}

export type SubPartner = Subscription &
	ISubscriptionPartner & {
		setSpaceMaxAllowed: (newSpaceMax: number) => void;
	};

/**
 * Represents a subscription partner.
 */
export default class SubscriptionPartner
	extends Subscription
	implements ToRefs<ISubscriptionPartner> {
	public spaceAllowed = ref<number>(0);

	public spaceAllowedMax = ref<number>(0);

	public clientsAmount = ref<number>(0);

	public route = ref<string>("");

	public isUnlimited = ref<boolean>(false);

	/**
	 *  Creates a new SubscriptionPartner instance.
	 *
	 * @param {SubscriptionConfig} config - The configuration object for the subscription partner
	 */
	public constructor (config: SubscriptionConfig) {
		super(config);

		this.fetchClients();
	}

	/**
	 * Defines the maximum allowed space for the subscription partner.
	 *
	 * @param spaceAllowedMax - The new maximum allowed space
	 */
	public setSpaceMaxAllowed (spaceAllowedMax: number): void {
		this.spaceAllowedMax.value = spaceAllowedMax ? spaceAllowedMax * Math.pow(1000, 4) : 0;
		this.isUnlimited.value = spaceAllowedMax === -1;
	}

	/**
	 * Fetches the clients for the subscription partner.
	 *
	 * @returns {Promise<void>} - A promise that resolves when the clients are fetched
	 */
	private async fetchClients (): Promise<void> {
		__subscription().setLoading(Sub.Type.Partner, true);
		await waitForPB();
		await __clients().fetchClients();
		const quota = __clients().getTotalQuotaAllowed;
		this.spaceAllowed.value = quota ? quota * Math.pow(1000, 4) : 0;
		__subscription().setLoading(Sub.Type.Partner, false);
	}
}
