import { __alert } from "~/stores/alert.store";
import { __identifier } from "~/stores/identifier.store";
import { __pbUser } from "~/stores/pb-user.store";

import type { Alert as AlertType } from "~/types/alert";
import { Sub } from "~/types/subscriptions";
import type { $t } from "~/types/t";

import type { SubObjectStorage } from "~/classes/subscriptions/SubscriptionObjectStorage";
import { translate } from "~/composables/i18n";

const identifiersLoaded = ref<boolean>(false);
export const hasFetchSubscriptions = ref<boolean>(false);

export async function checkNotifications (t: any, config: any): Promise<void> {
	await __pbUser().init();

	__identifier()
		.getIdentifiers()
		?.then(() => {
			identifiersLoaded.value = true;
		});
	watchS3Trial();
	watchDeactivated();
	watchProblem(t, config);
	checkPaymentStatus(t, config);
	await checkNoSubscription(false);
}

function displayTrialAlert (): void {
	const objectStorage = __subscription().get<SubObjectStorage>(Sub.Type.ObjectStorage);

	console.table({
		user: __user().user,
		hasObjectStorage: __subscription().has(Sub.Type.ObjectStorage),
		identifiersLoaded: identifiersLoaded.value,
		isTrial: objectStorage?.isTrial
	});

	if (
		!__user().user // If user is not initialized
		|| !__subscription().has(Sub.Type.ObjectStorage) // If user has no object storage subscription
		|| !identifiersLoaded.value // If identifiers are not loaded
		|| !objectStorage?.isTrial // If object storage subscription is not a trial
	) {
		console.warn("Not Display Trial Alert");
		return;
	}

	const trialInformations = objectStorage.getTrialInformations();
	console.table({
		sub: objectStorage,
		isTrial: objectStorage.isTrial,
		infos: trialInformations
	});
	if (objectStorage && objectStorage.isTrial && trialInformations) {
		console.warn("Display Trial Alert");
		const daysUntilEnd = daysBetweenNow(trialInformations.trialEnd);
		let message: string;
		let type = "info";

		let id = "s3-trial";
		if (unref(objectStorage.isQuotaReached)) {
			message = "modals.trial.content.quota_reached";
			type = "danger";
			id += "-quota-reached";
		} else {
			message = translate("modals.trial.content.remaining_days", {
				days: daysUntilEnd,
				count: daysUntilEnd
			});
			id += "-remaining-days";
		}

		console.warn("displayTrialAlert", {
			id,
			type: type as AlertType["type"],
			title: "modals.trial.title",
			message,
			actions: [
				{
					label: translate("label.contact"),
					severity: "primary",
					icon: "send-mail",
					href: "https://www.leviia.com/contact-pro/"
				}
			],
			isDismissible: false
		});
	}
}

function watchS3Trial (): void {
	if (!__subscription().get<SubObjectStorage>(Sub.Type.ObjectStorage)) return;
	watch(__subscription().get<SubObjectStorage>(Sub.Type.ObjectStorage), displayTrialAlert, {
		immediate: true
	});
}

function watchDeactivated (): void {
	let unwatch: any = null;
	unwatch = watchEffect(() => {
		if (
			__pbUser().isDeactivated
			|| (__subscription().has(Sub.Type.ObjectStorage)
				&& __subscription().getIsReadonly(Sub.Type.ObjectStorage)
				&& __pbUser().hasParent)
		) {
			__alert().add({
				id: "reseller-deactivated",
				type: "danger",
				isDismissible: false,
				title: "modals.partners.disabled_by_distributor.title",
				message: "modals.partners.disabled_by_distributor.content"
			});

			if (unwatch) {
				unwatch();
			}
		}
	});

	setTimeout(() => {
		if (unwatch) {
			unwatch();
		}
	}, 5000);
}

function watchProblem (t: any, config: any): void {
	const supportEmail = config.SUPPORT_EMAIL;

	const unwatch = watchEffect(() => {
		if (__user().hasProblem && __pbUser().isInitialized) {
			__alert().add({
				id: "no-payment",
				type: "danger",
				title: "modals.subscription_problem.title",
				message: t("modals.subscription_problem.content", createEmailLink(supportEmail)),
				isDismissible: false
			});

			unwatch();
		}
	});

	setTimeout(() => {
		unwatch();
	}, 5000);
}

function showUnpaidNotification (
	hasMultipleReadonly: boolean,
	isReadOnlyObjectStorage: boolean,
	t: $t,
	isReadOnlyPartner: boolean,
	isReadOnlyDrive: boolean,
	isReadOnlyDrivePro: boolean,
	supportEmail: any
): void {
	let subscriptionName = "";

	if (!hasMultipleReadonly) {
		if (isReadOnlyObjectStorage) {
			subscriptionName = t("label.subscription.object-storage");
		} else if (isReadOnlyPartner) {
			subscriptionName = t("label.subscription.partner-long");
		} else if (isReadOnlyDrive) {
			subscriptionName = t("label.subscription.drive");
		} else if (isReadOnlyDrivePro) {
			subscriptionName = t("label.subscription.drive-pro");
		}
	}

	const title = hasMultipleReadonly
		? t("notification.suspended_account.title.multiple")
		: t("notification.suspended_account.title.single", { sub: subscriptionName });

	__alert().add({
		id: "unpaid",
		type: "danger",
		title,
		message: t("notification.suspended_account.content"),
		isDismissible: false,
		actions: [
			{
				label: t("label.parameters"),
				route: "/user/subscriptions",
				severity: "secondary",
				icon: "settings"
			},
			{
				label: t("label.contact"),
				href: `mailto:${supportEmail}`,
				severity: "primary",
				icon: "send-mail"
			}
		]
	});
}

function checkPaymentStatus (t: any, config: any): void {
	const supportEmail = config.SUPPORT_EMAIL;

	const isReadOnlyObjectStorage = __subscription().getIsReadonly(Sub.Type.ObjectStorage);
	const isReadOnlyPartner = __subscription().getIsReadonly(Sub.Type.Partner);
	const isReadOnlyDrive = __subscription().getIsReadonly(Sub.Type.Drive);
	const isReadOnlyDrivePro = __subscription().getIsReadonly(Sub.Type.DrivePro);

	const readOnlySubscriptions = [
		isReadOnlyObjectStorage,
		isReadOnlyPartner,
		isReadOnlyDrive,
		isReadOnlyDrivePro
	];

	const hasReadOnly = readOnlySubscriptions.some(readOnly => readOnly);
	const hasMultipleReadonly = readOnlySubscriptions.filter(readOnly => readOnly).length > 1;
	let unwatch: any = null;
	unwatch = watchEffect(() => {
		if (!hasReadOnly || __pbUser().hasParent) {
			setTimeout(() => {
				unwatch();
			}, 5000);

			return;
		}

		showUnpaidNotification(
			hasMultipleReadonly,
			isReadOnlyObjectStorage,
			t,
			isReadOnlyPartner,
			isReadOnlyDrive,
			isReadOnlyDrivePro,
			supportEmail
		);

		if (unwatch) unwatch();
	});
}

export async function checkNoSubscription (forceNoWait = false): Promise<void> {
	// eslint-disable-next-line func-style
	let unwatch = (): void => {};

	if (!forceNoWait) {
		await waitForSubscription(Sub.Type.ObjectStorage);
	}

	function checkSub (): void {
		if (!hasFetchSubscriptions.value) {
			return;
		}

		if (__subscription().getSubscriptions.length === 0) {
			__alert().add({
				id: "no-payment",
				type: "info",
				title: "notification.no_sub.title",
				message: "notification.no_sub.content",
				isDismissible: false,
				actions: [
					{
						label: translate("notification.no_sub.cta.website"),
						href: "https://www.leviia.com",
						icon: "language",
						severity: "secondary"
					},
					{
						label: translate("notification.no_sub.cta.parameters"),
						route: "/user/billing/details",
						icon: "settings"
					}
				]
			});
		} else {
			__alert().remove("no-payment");
		}

		setTimeout(() => {
			unwatch();
		}, 30000);
	}

	if (forceNoWait) {
		checkSub();
	} else {
		unwatch = watch(() => __subscription().getSubscriptions, checkSub, {
			deep: true,
			immediate: true
		});
	}
}
