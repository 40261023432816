import { ref } from "vue";

import type { $t } from "~/types/t";

import type { TradKey } from "~/plugins/i18n";

export const globalT = ref<$t>();

export function translate (key: TradKey, ...args: unknown[]): string {
	if (!globalT.value) {
		console.error("Global translation function not set");
		return key;
	}

	return globalT.value(key, ...args);
}
