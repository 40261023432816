import { currentAccessToken } from "~/utils/keycloak";

import type { Route } from "~/routes/core/routes";

import type { CustomHeaders } from "~/types/fetch";

type MethodType = "GET" | "POST" | "PUT" | "DELETE";

const ENABLE_LOCAL_CACHE = true;

const pendingPromises: Record<string, Promise<any>> = {};

// noinspection t
export async function useCustomFetch<T> (
	requestUrl: string,
	requestMethod: MethodType = "GET",
	requestHeaders: CustomHeaders = {},
	requestBody: object | null = null,
	noBearer = false,
	withCache = true
): Promise<T> {
	try {
		const cacheKey = JSON.stringify({
			requestUrl,
			requestMethod,
			requestHeaders,
			requestBody
		});
		if (ENABLE_LOCAL_CACHE && withCache) {
			const cachedData = localStorage.getItem(cacheKey);
			if (cachedData) {
				const { data, timestamp } = JSON.parse(cachedData);
				const config = useRuntimeConfig().public;
				const isExpired
          = Date.now() - timestamp
          > parseInt(`${config.LOCAL_CACHE_DURATION ?? 20000}`);
				if (!isExpired) {
					return data;
				}
				localStorage.removeItem(cacheKey);
			}

			if (pendingPromises[cacheKey] !== undefined) {
				return await pendingPromises[cacheKey];
			}
		}

		const options: {
      method: MethodType;
      headers: CustomHeaders;
      body?: string;
    } = {
    	method: requestMethod,
    	headers: {
    		...requestHeaders
    	}
    };

		// @ts-ignore
		if (!noBearer && !options?.headers?.Authorization) {
			options.headers = {
				...options.headers,
				Authorization: `Bearer ${currentAccessToken.value}`
			};
		}

		if (requestMethod !== "GET" && requestBody !== null && options.headers) {
			options.body = JSON.stringify(requestBody);
			options.headers = {
				...options.headers,
				"Content-Type": "application/json"
			};
		}

		const promise = $fetch<T>(requestUrl, {
			...options
		}).then(data => {
			const result = data as T;
			if (ENABLE_LOCAL_CACHE && withCache) {
				localStorage.setItem(
					cacheKey,
					JSON.stringify({ data: result, timestamp: Date.now() })
				);
				delete pendingPromises[cacheKey];
			}
			return result;
		});

		pendingPromises[cacheKey] = promise;
		return await promise;
	} catch (e) {
		console.error(e);
		return null as unknown as T;
	}
}

type RequestObject = Record<string, any>;

function replaceUrlParams (url: string, requestBody: RequestObject): string {
	const regex = /\[(\w+)]/g;
	return url.replace(regex, (_, paramName: string) => {
		if (requestBody[paramName] !== undefined) {
			return requestBody[paramName];
		} else {
			throw new Error(`Missing value for URL parameter: ${paramName}`);
		}
	});
}

export async function useFetchRoute<T> (
	route: Route,
	urlParams: object | null = null,
	body: object | null = null,
	headers: CustomHeaders = {}
): Promise<T> {
	try {
		const url = replaceUrlParams(route.path, urlParams ?? {});
		const response = await useCustomFetch(
			url,
			route.method,
			{
				...headers,
				"Leviia-Route": route.path
			},
			body,
			false,
			route.withCache
		);

		return response as T;
	} catch (e) {
		console.error(e);
		return null as unknown as T;
	}
}

const PROTOCOL_HTTP = "http://";
const PROTOCOL_HTTPS = "https://";
const ROUTE_PARAM_REGEX = /\[(\w+)]/g;

export function normalizePath (path: string): string {
	path = path
		.replaceAll(ROUTE_PARAM_REGEX, "")
		.replaceAll(PROTOCOL_HTTP, "")
		.replaceAll(PROTOCOL_HTTPS, "");
	while (path.startsWith("/")) {
		path = path.slice(1);
	}
	while (path.endsWith("/")) {
		path = path.slice(0, -1);
	}
	return path;
}

export function clearCacheForRoute (route: Route): void {
	const normalizedPath = normalizePath(route.path);
	const localStorageKeys = Object.keys(localStorage);
	const cacheKeys = localStorageKeys.filter(key => key.includes(normalizedPath));

	cacheKeys.forEach(key => {
		localStorage.removeItem(key);
	});

	log(
		"Vider le cache pour le chemin:",
		normalizedPath,
		`${cacheKeys.length} éléments supprimés`
	);
}
