import { __pbUser } from "~/stores/pb-user.store";

export async function waitForPB (): Promise<void> {
	await __pbUser().init();

	function checkPB (
		timeout: NodeJS.Timeout,
		interval: NodeJS.Timeout,
		resolve: (value: void | PromiseLike<void>) => void
	): void {
		if (!__pbUser().isLoading) {
			clearTimeout(timeout);
			clearInterval(interval);
			resolve();
		}
	}

	// wait until __pbUser().isLoading is false, after 5s timeout, try every 100ms
	return new Promise((resolve, reject) => {
		const timeout = setTimeout(() => {
			reject(new Error("PB timeout"));
		}, 5000);

		const interval = setInterval(() => {
			checkPB(timeout, interval, resolve);
		}, 500);

		checkPB(timeout, interval, resolve);
	});
}
