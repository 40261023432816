import type { Ref, ToRefs } from "vue";

import { routesNextcloud } from "~/routes/declarations";

import type { DriveInfos } from "~/types/drive";
import { Sub } from "~/types/subscriptions";

import Subscription, { type SubscriptionConfig } from "~/classes/Subscription";

/**
 * Represents drive subscription information.
 *
 * @interface
 * @property {boolean} isActive - Whether the subscription is active
 * @property {boolean} isDisabled - Whether the subscription is disabled
 * @property {boolean} isLoading - Whether the subscription is loading
 * @property {number} spaceUsed - The amount of space used
 * @property {number} spaceTotal - The total amount of space
 * @property {number} amountUsers - The amount of users
 * @property {string} url - The URL of the drive
 */
export interface ISubscriptionDrive {
	spaceUsed: number;
	spaceTotal: number;
	amountUsers: number;
	url: string;

	get getUrl(): string;
}

export type SubDrive = Subscription & ISubscriptionDrive;

/**
 * Represents a subscription drive.
 */
export default class SubscriptionDrive extends Subscription implements ToRefs<ISubscriptionDrive> {
	public spaceUsed = ref<number>(0);

	public spaceTotal = ref<number>(0);

	public amountUsers = ref<number>(1);

	public url = ref<string>("");

	/**
	 *  Creates a new SubscriptionDrive instance.
	 *
	 * @param {SubscriptionConfig} config - The configuration object for the subscription drive
	 */
	public constructor (config: SubscriptionConfig) {
		super(config);

		this.defineURL();
		__subscription().setLoading(Sub.Type.Drive, true);
		this.fetchDriveData()
			.then(() => __subscription().setLoading(Sub.Type.Drive, false))
			.catch((error: any) => {
				__subscription().setLoading(Sub.Type.Drive, false);
				console.error(error);
			});
	}

	/**
	 * Gets the URL of the drive.
	 *
	 * @returns {Ref<string>} - The URL of the drive
	 */
	public get getUrl (): Ref<string> {
		return this.url;
	}

	/**
	 * Sets the URL based on the runtime configuration.
	 * Throws an error if the URL is not defined in the runtime config.
	 *
	 * @throws {Error} - Error message: "URL_CLOUD not defined in runtime config"
	 */
	private defineURL (): void {
		const url = this.runtimeConfig.URL_CLOUD;
		if (!url) {
			throw new Error("URL_CLOUD not defined in runtime config");
		}

		this.url.value = url;
	}

	/**
	 * Fetches drive data.
	 *
	 * @returns {Promise<void>} - A promise that resolves when the drive data are fetched
	 */
	private async fetchDriveData (): Promise<void> {
		const infos = await useFetchRoute<Partial<DriveInfos>>(routesNextcloud.driveInfos);
		if (!infos) {
			throw new Error("Failed to fetch drive data");
		}

		this.setSpaceUsed(infos.quota?.used);
		this.setSpaceTotal(infos.quota?.total);
	}

	/**
	 * Sets the used space.
	 *
	 * @param {number} spaceUsed - The used space in bytes
	 */
	private setSpaceUsed (spaceUsed: number | string | undefined): void {
		if (!spaceUsed) {
			return;
		}

		this.spaceUsed.value = parseInt(`${spaceUsed}`);
	}

	/**
	 * Sets the total space.
	 *
	 * @param {number} spaceTotal - The total space in bytes
	 */
	private setSpaceTotal (spaceTotal: number | string | undefined): void {
		if (!spaceTotal) {
			return;
		}

		this.spaceTotal.value = parseInt(`${spaceTotal}`);
	}
}
